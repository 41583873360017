const Inter1 = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6943924",
    space_js: "//sjgfzpgb.timesaurora.com/production/pk/source/j-sgc-szg.js"
  }
];

const Banner1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943925",
    space_js: "//sjgfzpgb.timesaurora.com/production/q/resource/l/openjs/k/thdt-ai.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943926",
    space_js: "//sjgfzpgb.timesaurora.com/production/r/static/mluie/source/u/production/b_l.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943927",
    space_js: "//sjgfzpgb.timesaurora.com/common/s/static/nm_v_jfvc_o.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943928",
    space_js: "//sjgfzpgb.timesaurora.com/source/t_o/nwkgw/static/d_q.js"
  }
];

export const SPACE_MAP = {
  // 首页官方插屏
  HomeInter: Inter1,
  // 首页banner
  HomeBanner: Banner1,
  // 首页信息流
  HomeNative: Native1,
  HomeNativeCenter1: Native2,
  HomeNativeCenter2: Native3,
  // 菜谱详情页banner
  RecipeDetailBanner: Banner1,
  // 菜谱详情页信息流
  RecipeDetailNative: Native1,
  // 话题详情页banner
  TopicDetailBanner: Banner1,
  // 话题详情页信息流
  TopicDetailNative: Native1,
  // 百科详情页banner
  BaikeDetailBanner: Banner1,
  // 百科详情页信息流
  BaikeDetailNative: Native1,
  // 节气详情页banner
  SolarDetailBanner: Banner1,
  // 结果页官方插屏弹窗
  ResultInter: Inter1,
  // 答题官方插屏
  AnswerInter: Inter1,
  ListInter: Inter1,
  ListBanner: Banner1,
  ListNative: Native1,
  ListNativeCenter1: Native2,
  ListNativeCenter2: Native3
};

export const foodsolarList = [
  {
    id: "1",
    color: "background: linear-gradient(180deg, rgba(0, 163, 129, 0) 0%, #00A381 10%);",
    primary: "#00A381",
    solar_term: "立春"
  },
  {
    id: "2",
    color: "background: linear-gradient(180deg, rgba(106, 139, 172, 0) 3%, #698AAB 10%);",
    primary: "#698AAB",
    solar_term: "雨水"
  },
  {
    id: "3",
    color: "background: linear-gradient(180deg, rgba(113, 166, 183, 0) 3%, #71A6B7 11%);",
    primary: "#71A6B7",
    solar_term: "惊蛰"
  },
  {
    id: "4",
    color: "background: linear-gradient(180deg, rgba(134, 52, 49, 0) 3%, #853330 11%);",
    primary: "#853330",
    solar_term: "春分"
  },
  {
    id: "5",
    color: "background: linear-gradient(180deg, rgba(134, 52, 49, 0) 3%, #853330 11%);",
    primary: "#853330",
    solar_term: "清明"
  },
  {
    id: "6",
    color: "background: linear-gradient(180deg, rgba(81, 101, 37, 0) 3%, #506525 11%);",
    primary: "#506525",
    solar_term: "谷雨"
  },
  {
    id: "7",
    color: "background: linear-gradient(180deg, rgba(0, 163, 129, 0) 0%, #00A381 10%);",
    primary: "#00A381",
    solar_term: "立夏"
  },
  {
    id: "8",
    color: "background: linear-gradient(180deg, rgba(81, 101, 37, 0) 3%, #506525 11%);",
    primary: "#506525",
    solar_term: "小满"
  },
  {
    id: "9",
    color: "background: linear-gradient(180deg, rgba(81, 101, 37, 0) 3%, #506525 11%);",
    primary: "#506525",
    solar_term: "芒种"
  },
  {
    id: "10",
    color: "background: linear-gradient(180deg, rgba(71, 131, 132, 0) 3%, #478384 11%);",
    primary: "#478384",
    solar_term: "夏至"
  },
  {
    id: "11",
    color: "background: linear-gradient(180deg, rgba(71, 131, 132, 0) 3%, #478384 11%);",
    primary: "#478384",
    solar_term: "小暑"
  },
  {
    id: "12",
    color: "background: linear-gradient(180deg, rgba(134, 52, 49, 0) 3%, #853330 11%);",
    primary: "#853330",
    solar_term: "大暑"
  },
  {
    id: "13",
    color: "background: linear-gradient(180deg, rgba(176, 147, 59, 0) 3%, #AF923A 11%);",
    primary: "#AF923A",
    solar_term: "立秋"
  },
  {
    id: "14",
    color: "background: linear-gradient(180deg, rgba(176, 147, 59, 0) 3%, #AF923A 11%);",
    primary: "#AF923A",
    solar_term: "处暑"
  },
  {
    id: "15",
    color: "background: linear-gradient(180deg, rgba(186, 141, 71, 0) 3%, #B98C46 11%);",
    primary: "#B98C46",
    solar_term: "白露"
  },
  {
    id: "16",
    color: "background: linear-gradient(180deg, rgba(186, 141, 71, 0) 3%, #B98C46 11%);",
    primary: "#B98C46",
    solar_term: "秋分"
  },
  {
    id: "17",
    color: "background: linear-gradient(180deg, rgba(186, 141, 71, 0) 3%, #B98C46 11%);",
    primary: "#B98C46",
    solar_term: "寒露"
  },
  {
    id: "18",
    color: "background: linear-gradient(180deg, rgba(166, 165, 196, 0) 3%, #A6A5C4 11%);",
    primary: "#A6A5C4",
    solar_term: "霜降"
  },
  {
    id: "19",
    color: "background: linear-gradient(180deg, rgba(129, 40, 55, 0) 3%, #812937 11%);",
    primary: "#812937",
    solar_term: "立冬"
  },
  {
    id: "20",
    color: "background: linear-gradient(180deg, rgba(166, 165, 196, 0) 3%, #A6A5C4 11%);",
    primary: "#A6A5C4",
    solar_term: "小雪"
  },
  {
    id: "21",
    color: "background: linear-gradient(180deg, rgba(166, 165, 196, 0) 3%, #A6A5C4 11%);",
    primary: "#A6A5C4",
    solar_term: "大雪"
  },
  {
    id: "22",
    color: "background: linear-gradient(180deg, rgba(158, 177, 194, 0) 3%, #9DB0C1 11%);",
    primary: "#9DB0C1",
    solar_term: "冬至"
  },
  {
    id: "23",
    color: "background: linear-gradient(180deg, rgba(158, 177, 194, 0) 3%, #9DB0C1 11%);",
    primary: "#9DB0C1",
    solar_term: "小寒"
  },
  {
    id: "24",
    color: "background: linear-gradient(180deg, rgba(158, 177, 194, 0) 3%, #9DB0C1 11%);",
    primary: "#9DB0C1",
    solar_term: "大寒"
  }
];
